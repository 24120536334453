
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




























































































.option-card {
  text-align: center;

  ::v-deep .product-card__main {
    padding: 3rem 2.5rem;
  }
}

.option-card__icon {
  max-width: 100%;
  max-height: 4rem;
  margin-bottom: $spacing;
  fill: $c-promo-card;
}

.option-card__title {
  @extend %fw-black;
  @include fluid-type(15, 24);

  margin: 0;
  color: $c-promo-card;
  line-height: 1;
}

.option-card__text {
  @include fluid-type(14, 15);

  margin-top: 2rem;
  line-height: 1.3;
}

.option-card__btn {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  margin-top: $spacing;
}

.option-card__price {
  margin-left: 0;

  ::v-deep .price__main,
  ::v-deep .price__stack {
    color: $white;
  }

  ::v-deep .price__main {
    width: auto;
  }
}

::v-deep .product-card__inner {
  overflow: hidden;
}

::v-deep .product-card__details {
  background-color: $c-bunting;
  color: $c-light;
}
